/* src/components/ProductInfoCard/ProductInfoCard.css */

/* Dark overlay background */
.product-info-card-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;
  padding: 0px;
}

/* Popup content styling */
.product-info-card-content {
  position: relative;
  width: 600px !important;
  height: 400px !important;
  background-color: #111 !important;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  border: 0px !important;
  overflow: hidden !important;
}

/* Close button styling */
.product-info-card-close-button,
.product-info-card-login-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
}

/* Image styling */
.product-info-card-image {
  width: 60%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

/* Text content styling */
.product-info-card-text {
  text-align: center;
  padding: 0px;
}

.product-info-card-text h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #fff;
}

/* Action container to hold the button or messages */
.product-info-card-action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 20px;
}

/* Play button styling */
.product-info-card-play-button,
.product-info-card-buy-now {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Specific styling for Play Now button */
.product-info-card-play-button {
  background-color: #9a51e2;
}

.product-info-card-play-button:hover {
  background-color: #7a3db0;
}

/* Specific styling for Purchase Now button */
.product-info-card-buy-now {
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
}

.product-info-card-buy-now:hover {
  background: linear-gradient(135deg, #ff4b2b, #ff416c);
}

/* Span inside Purchase Now button */
.product-info-card-buy-now span {
  margin-left: 8px;
}

/* Alert message styling */
.product-info-card-alert-message {
  color: #ffffff;
  background-color: #9a51e2;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  width: auto;
  border-radius: 5px;
}

/* Loading message styling */
.product-info-card-loading-message {
  color: #ffffff;
  background-color: #9a51e2;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  width: auto;
  border-radius: 5px;
}

/* Responsive styling for smaller and horizontal screens */
@media (max-width: 768px) {
  .product-info-card-content {
    width: 90%;
    max-width: 90%;
    height: auto;
    max-height: 90vh;
    padding: 10px;
  }

  .product-info-card-image {
    width: 60%;
    max-width: 300px; /* Smaller max size for horizontal screens */
    margin-bottom: 15px;
  }

  .product-info-card-text h3 {
    font-size: 20px;
  }

  .product-info-card-play-button,
  .product-info-card-buy-now {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Responsive styling for landscape orientation */
@media (orientation: landscape) and (max-height: 500px) {
  .product-info-card-image {
    width: 50%;
    max-width: 250px; /* Reduce size for landscape mode */
  }
  .product-info-card-content {
    max-height: 80vh;
  }
}
