.vjs-switch-camera-button:before {
    content: "";
    background-image: url('../custom-icons/camera-reels.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 52%;
    margin-left: 15%;
    margin-top: 0%;
}

.vjs-switch-audio-button:before {
    content: "";
    background-image: url('../custom-icons/headphones.svg');
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 75%;
    margin-left: 8%;
    margin-top: 0%;
}

.cameraSwitch span.vjs-menu-item-text, .audioSwitch span.vjs-menu-item-text{
    text-transform: Capitalize!important;
}

.cameraSwitch .vjs-menu-item:first-child, .audioSwitch .vjs-menu-item:first-child{
    pointer-events: none!important;
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
    line-height: 2em;
    padding: 0;
    margin: 0 0 0.3em 0;
    font-weight: bold;
    cursor: default;
}

div.vjs-quality-menu.vjs-menu-opened > li.vjs-menu-item.vjs-menu-title.vjs-menu-custom-header > span.vjs-menu-item-text, div.vjs-camera-menu.vjs-menu-opened > li.vjs-menu-item.vjs-menu-title.vjs-menu-custom-header > span.vjs-menu-item-text, div.vjs-audio-menu.vjs-menu-opened > li.vjs-menu-item.vjs-menu-title.vjs-menu-custom-header > span.vjs-menu-item-text, div.vjs-subtitles-menu.vjs-menu-opened > li.vjs-menu-item.vjs-menu-title.vjs-menu-custom-header > span.vjs-menu-item-text {
    font-size: 1.1em;
}

.vjs-camera-menu,
.vjs-audio-menu,
.vjs-quality-menu,
.vjs-subtitles-menu {
    display: none;
    width: 90%;
    max-width: 250px;
    max-height: 70vh;
    position: absolute;
    bottom: 60px;
    right: 1%; /* Margen reducido */
    background-color: rgba(43, 51, 63, 0.7);
    overflow-y: auto;
    z-index: 1;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    border-radius: 4px;
    padding: 10px;
}

.vjs-camera-menu ul,
.vjs-audio-menu ul,
.vjs-quality-menu ul,
.vjs-subtitles-menu ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

.vjs-camera-menu li,
.vjs-audio-menu li,
.vjs-quality-menu li,
.vjs-subtitles-menu li{
    padding: 5px 0;
    cursor: pointer;
}

.vjs-camera-menu li:hover,
.vjs-audio-menu li:hover,
.vjs-quality-menu li:hover,
.vjs-subtitles-menu li:hover{
    background-color: rgba(255, 255, 255, 0.1);
}


.vjs-menu-opened {
    display: block;
    transform: translateX(0);
}

.vjs-menu-opened * {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.vjs-menu-item-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    padding: 10px 0;
}

.vjs-menu-item {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 1.5em;
}

.vjs-camera-thumbnail, .vjs-audio-thumbnail {
    width: 16%;
    height: 16%;
    margin-right: 20px;
    object-fit: cover;
}

.vjs-subtitles-thumbnail-settings, .vjs-subtitles-thumbnail-disabled, .vjs-subtitles-thumbnail {
    width: 10%;
    height: 10%;
    margin-right: 25px;
    object-fit: cover;
    margin-left: 8px;
}

li.vjs-menu-item {
    margin: 0 0 0 0!important;
}

.vjs-menu-item {
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.vjs-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.25);
}

li.vjs-menu-item.vjs-selected {
    background-color: rgba(255, 255, 255, 0.25);
}

.vjs-switch-quality-button.vjs-icon-hd:before {
    font-size: 2em;
}