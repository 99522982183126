/* Slider container */
.product-carousel-container {
  width: 80%;
  margin: 0 auto;
  padding: 0 40px;
  text-align: left;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

/* Align slides to the left */
.slick-track {
  display: flex !important; /* Use flexbox */
  justify-content: flex-start !important; /* Align items to the start */
  align-items: stretch;
}

/* Each slide */
.carousel-slide {
  flex: none;
  width: calc(20% - 30px); /* Adjust width according to the number of slides */
  padding: 0 15px; /* Uniform spacing */
  box-sizing: border-box;
}

/* Product images */
.product-image {
  width: 95%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

/* Hover effect on images */
.product-image:hover {
  transform: scale(1.05);
}

/* Slider title */
.slider-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-bottom: 40px;
  text-align: left;
  position: relative;
  display: inline-block;
}

/* Underline effect for the title */
.slider-title::after {
  content: '';
  display: block;
  width: 50px;
  height: 4px;
  background-color: #9a51e2;
  margin-top: 5px;
  position: absolute;
  left: 0;
  bottom: -5px;
}

/* Slider arrows */
.slick-prev,
.slick-next {
  z-index: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.slick-prev {
  left: -25px;
}

.slick-next {
  right: -25px;
}

.slick-prev::before,
.slick-next::before {
  color: white;
  font-size: 20px;
}

/* Responsive design */
@media (max-width: 1200px) {
  .carousel-slide {
    width: calc(25% - 30px); /* 4 items on medium screens */
  }
}

@media (max-width: 992px) {
  .carousel-slide {
    width: calc(33.33% - 30px); /* 3 items on smaller screens */
  }
}

@media (max-width: 768px) {
  .carousel-slide {
    width: calc(50% - 30px); /* 2 items on small screens */
  }
}

@media (max-width: 576px) {
  .carousel-slide {
    width: calc(100% - 30px); /* 1 item on extra small screens */
  }
}
