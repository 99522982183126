.vjs-exit-button {
    position: absolute!important;
    width: auto!important;
    height: auto!important;
    top: 2.5%;
    left: 1.5%;
    z-index: 1000;
    background-color: transparent;
    border: none;
    color: white;
}

.vjs-exit-button:hover {
    background-color: transparent!important;
    cursor: pointer;
}

.vjs-exit-button svg {
    height: 2.5em;
    transition: filter 0.3s ease;
}

.vjs-exit-button:hover svg {
    fill: #9a51e2;
    /*filter: brightness(0.6);*/
}

.vjs-exit-button:focus svg {
    fill: #9a51e2;
    /*filter: brightness(0.6);*/
}

.vjs-exit-button:active svg {
    fill: #9a51e2;
    /*filter: brightness(0.6);*/
}

#top-left-icon {
    height: 4em;
}