/* Ensure full-screen coverage for all screen sizes */
body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  background-color: #111;
  overflow-x: hidden;
}

html {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  background-color: #111;
  overflow-x: hidden;
}

/* Video Slider Container */
.video-slider-container {
  position: relative;
}

.video-background {
  width: 100%;
  height: 62vh;
  min-height: 62vh;
  max-height: 62vh; /* Ensure the container height remains fixed */
  overflow: hidden;
  background-color: black;
  display: flex; /* Center the video */
  justify-content: center;
  align-items: center;
}

.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio while covering the container */
  background-color: #111;
}

/* Centered content */
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 5;
  width: 100%;
  max-width: 90%;
  padding: 0 10px;
}

.content-title {
  font-size: 4rem;
  margin: 0;
  padding: 0;
}

.content-subtitle {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
}

@media (max-width: 1024px) {
  .content-title {
    font-size: 3rem;
  }

  .content-subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .content-title {
    font-size: 2rem;
  }

  .content-subtitle {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .content-title {
    font-size: 1.5rem;
  }

  .content-subtitle {
    font-size: 0.8rem;
  }
}

/* Swiper Pagination */
.swiper-pagination {
  position: absolute;
  bottom: 30px !important; /* Move bullets a bit higher */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: white !important; /* Override default blue */
  opacity: 0.6 !important;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.swiper-pagination-bullet-active {
  background-color: #9a51e2 !important; /* Override default active bullet */
  opacity: 1 !important;
}
