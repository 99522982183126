/* VerificationError.css */

/* Container for the verification error component */
.verification-error-container {
  position: relative; /* Ensures the container flows within the document */
  max-width: 500px;
  margin: 80px auto; /* Centers the container horizontally with top margin */
  padding: 40px 30px;
  /*background-color: #a94442;  Dark red background to indicate error */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8); /* Subtle shadow for depth */
  text-align: center;
  color: #fff; /* White text for contrast */
  z-index: 1; /* Ensures it sits above default elements but below higher overlays if any */
}

/* Title styling */
.verification-error-container h2 {
  font-size: 24px; /* Consistent with .login-signup-title */
  color: #fff; /* White text */
  margin-bottom: 20px;
}

/* Paragraph styling */
.verification-error-container p {
  font-size: 16px;
  margin-bottom: 15px;
  color: #fff; /* White text for contrast */
}

/* Link styling */
.verification-error-link {
  color: #9a51e2; /* Matching button color */
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.verification-error-link:hover {
  color: #7a3db0; /* Darker shade on hover */
}
