/* Estilos para el contenedor del popup */
.info-popup {
    display: none; /* Oculto por defecto */
    position: fixed; /* Fijo en la pantalla */
    z-index: 1001; /* Encima de otros elementos */
    left: 0;
    top: 0;
    width: 100%; /* Ancho completo */
    height: 100%; /* Alto completo */
    overflow: auto; /* Habilitar scroll si es necesario */
    background-color: rgba(0,0,0,0.4); /* Fondo semitransparente */
    display: flex!important; /* Usa Flexbox para el contenedor */
    justify-content: center; /* Centra horizontalmente el contenido */
    align-items: center; /* Centra verticalmente el contenido */
}

/* Estilos para el contenido del popup */
.popup-content {
    background-color: #fefefe;
    margin: auto; /* Ajusta para auto para centrar dentro del flex container */
    padding: 2.5%;
    border: 1px solid #888;
    width: fit-content; /* Ajusta el ancho según el contenido */
    max-width: 80%; /* Limita el ancho máximo para evitar el desbordamiento */
    border-radius: 10px; /* Bordes redondeados */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Añade sombra para profundidad */
    overflow: auto; /* Permite scroll en el contenido si es necesario */
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 30px!important;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

/* Estilos específicos para el texto dentro del popup */
.info-popup h2 {
    color: #444 !important; /* Un color más oscuro para mayor contraste */
    font-size: 24px !important; /* Tamaño más grande para destacar */
    font-weight: 700 !important; /* Hacer el texto más grueso */
    margin-top: 0 !important; /* Ajustar espaciado superior si es necesario */
    margin-bottom: 20px !important; /* Espaciado inferior para separarlo de los párrafos */
    text-align: center !important; /* Centrar el título para darle más presencia */
    font-family: 'Arial', sans-serif !important; /* Asegurar la fuente */
}

.info-popup p {
    color: #333 !important; /* Color para los párrafos */
    text-align: left !important; /* Alineación del texto */
    font-family: Arial, sans-serif !important; /* Fuente para compatibilidad */
    margin: 10px 0 !important; /* Espaciado uniforme */
}

.vjs-icon-info {
    background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwMHB4IiB3aWR0aD0iMjAwcHgiIHZlcnNpb249IjEuMSIgaWQ9Il94MzJfIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iI2ZmZmZmZiIgc3Ryb2tlPSIjZmZmZmZmIj48ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCI+PC9nPjxnIGlkPSJTVkdSZXBvX3RyYWNlckNhcnJpZXIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PC9nPjxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHN0eWxlIHR5cGU9InRleHQvY3NzIj4gLnN0MHtmaWxsOiNmZmZmZmY7fSA8L3N0eWxlPiA8Zz4gPHBhdGggY2xhc3M9InN0MCIgZD0iTTI5MC42NzEsMTM1LjQzNGMzNy4zMjQtMy4yNjMsNjQuOTQ5LTM2LjE3NSw2MS42NjMtNzMuNDk4Yy0zLjI0MS0zNy4zMjQtMzYuMTUyLTY0LjkzOC03My40NzYtNjEuNjc1IGMtMzcuMzI0LDMuMjY0LTY0Ljk0OSwzNi4xNjQtNjEuNjg2LDczLjQ4OEMyMjAuNDM3LDExMS4wOTYsMjUzLjM0OCwxMzguNjk4LDI5MC42NzEsMTM1LjQzNHoiPjwvcGF0aD4gPHBhdGggY2xhc3M9InN0MCIgZD0iTTMxMS4zMSw0MDYuMzU0Yy0xNi4xMzQsNS45MDYtNDMuMzIyLDIyLjU0Ni00My4zMjIsMjIuNTQ2czIwLjYxNS05NS4yOTcsMjEuNDY2LTk5LjQ0NiBjOC43MS00MS44MjksMzMuNDYzLTEwMC44Ni0wLjA2OS0xMzYuNzQ3Yy0yMy4zNS0yNC45MzYtNTMuMzY2LTE4LjIyNS03OS44MTksNy4wNzljLTE3LjQ2NywxNi42OTYtMjYuNzI5LDI3LjM3Mi00Mi45MDgsNDUuMzIyIGMtNi41NSw3LjI3My05LjAzMiwxNC4wNjUtNS45MywyNC43MTdjMy4zMzIsMTEuNTE1LDE2LjgsMTcuMjI2LDI4LjcwNSwxMi44NzFjMTYuMTM0LTUuODk1LDQzLjMtMjIuNTM0LDQzLjMtMjIuNTM0IHMtMTIuNTk1LDU3Ljk5Ny0xOC44NjksODdjLTAuODc0LDQuMTM3LTM2LjA2LDExMy4yOTItMi41MDUsMTQ5LjE4YzIzLjM1LDI0Ljk0OSw1My4zNDMsMTguMjI2LDc5LjgxOS03LjA2NiBjMTcuNDY3LTE2LjY5OCwyNi43MjktMjcuMzczLDQyLjkwOC00NS4zMzRjNi41NS03LjI2Myw5LjAwOS0xNC4wNTQsNS45My0yNC43MDZDMzM2LjY2LDQwNy43MzMsMzIzLjIxNSw0MDIuMDEsMzExLjMxLDQwNi4zNTR6Ij48L3BhdGg+IDwvZz4gPC9nPjwvc3ZnPg==') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center !important;
    width: 1.8em !important;
}
