.video-js .vjs-button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    display: flex;
    justify-content: center;
    transform: translate(-50%, -50%);
    display: none;
}

.video-js .vjs-button-container .vjs-play-control {
    margin-left: 6%!important;
    margin-right: 6%!important;
}

.video-js .vjs-seek-button,
.video-js .vjs-button-container .vjs-play-control {
    font-family: 'VideoJS';
    cursor: pointer;
    font-weight: 400;
    font-style: normal;
    font-size: 3em; /* Ajusta el tamaño de fuente según sea necesario */
    height: 3em; /* Ajusta la altura según sea necesario */
}

/* CSS para móviles */
@media screen and (max-width: 800px) {
    .video-js .vjs-seek-button,
    .video-js .vjs-button-container .vjs-play-control {
        font-size: 1.8em;
    }
}

/* CSS para móviles */
@media screen and (max-width: 500px) {
    .video-js .vjs-seek-button,
    .video-js .vjs-button-container .vjs-play-control {
        font-size: 1.5em;
    }
}

.video-js .vjs-seek-button:hover,
.video-js .vjs-button-container .vjs-play-control:hover {
    color: #9a51e2;
    background: transparent;
}

.video-js.vjs-user-active .vjs-button-container,
.video-js.vjs-user-inactive.vjs-playing .vjs-button-container {
    display: flex; /* El contenedor se muestra cuando el video está jugando o cuando el mouse está sobre el reproductor */
}

.video-js .vjs-seek-button.skip-back .vjs-icon-placeholder::before {
    content: '\f11d';
}

.video-js .vjs-seek-button.skip-forward .vjs-icon-placeholder::before {
    content: '\f120';
}

.vjs-big-vr-play-button:visible ~ .vjs-button-container:not(:hidden) {
    display: none;
}

.vjs-loading-spinner {
    display: none !important;
}

/* Estilos Playback Rates */
.video-js .vjs-playback-rate .vjs-menu-content {
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro ligeramente transparente */
    border-radius: 6px; /* Esquinas redondeadas */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Sombra más suave y dispersa */
    overflow: hidden; /* Para mantener las esquinas redondeadas */
}

.video-js .vjs-playback-rate .vjs-menu-item {
    padding: 10px 16px; /* Relleno ajustado */
    color: #fff; /* Texto en blanco */
    font-size: 14px; /* Tamaño de letra */
    transition: background-color 0.2s ease; /* Transición suave para el cambio de color */
}

.video-js .vjs-playback-rate .vjs-menu-item.vjs-selected {
    background-color: #9a51e2!important; /* Color púrpura para la opción seleccionada */
    color: #fff; /* Texto en blanco para contrastar con el fondo púrpura */
}

.video-js .vjs-playback-rate .vjs-menu-item:not(.vjs-selected):hover {
    background-color: #9a51e2!important; /* Un toque de resaltado al hacer hover */
}


#tomove or delete
.video-js .vjs-big-vr-play-button {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: auto !important;
    width: 100px !important;
    height: 100px !important;
    background-size: contain !important;
    background-color: rgba(0, 0, 0, .5) !important;
}
#video-container {
    position: relative;
    width: 100%;
    max-height: 100vh; /* No excede la altura de la ventana */
    overflow: hidden; /* Oculta cualquier desbordamiento */
}

.intoreality-player {
    padding-top: 49.6354%;
    width: 100%;
    height: auto; /* Ajusta la altura automáticamente según la anchura */
    max-height: 100%; /* Asegura que el video no exceda la altura del contenedor */
    object-fit: contain; /* Mantiene la proporción del video dentro del contenedor */
}
body {
    margin: 0px;
}
.video-js .vjs-control-bar {
    background-color: rgba(0, 0, 0, 0.3) !important;
    color: #ffffff;
    font-size: 1.07em;
}
.video-js .vjs-control-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3em;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7);
}
.video-js {
    font-size: 14px;
    overflow: hidden;
}
