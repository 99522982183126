/* VerifyEmail.css */

/* Container for the email verification component */
.verify-email-container {
  max-width: 500px;
  margin: 80px auto;
  padding: 40px 30px;
  background-color: #111; /* Matching the dark background */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  text-align: center;
}

/* Title styling */
.verify-email-container h2 {
  font-size: 24px; /* Consistent with .login-signup-title */
  color: #fff; /* White text */
  margin-bottom: 20px;
}

/* Message styling */
.verify-email-container p {
  font-size: 16px;
  margin-bottom: 15px;
  color: #ddd; /* Light grey for readability on dark background */
}

/* Link styling */
.verify-email-container a {
  color: #9a51e2; /* Matching button color */
  text-decoration: underline;
  font-weight: 500;
}

.verify-email-container a:hover {
  color: #7a3db0; /* Darker shade on hover */
}

/* Spinner styling */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1); /* Light spinner border */
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #9a51e2; /* Spinner color matches buttons */
  animation: spin 1s ease infinite;
  margin: 20px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
