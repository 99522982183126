.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-popup {
  position: absolute;
  top: 80px;
  right: 80px; /* Mover un poco hacia la izquierda */
  z-index: 999;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.search-input {
  padding: 10px;
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-results {
  margin-top: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 300px; /* Ajustar altura máxima */
  overflow-y: auto;
  padding: 0 10px; /* Asegura que los lados izquierdo y derecho estén alineados */
}

.search-item {
  display: flex;
  align-items: center;
  padding: 10px 0; /* Padding superior e inferior igualado */
  border-bottom: 1px solid #eee;
}

.search-item:last-child {
  border-bottom: none; /* Eliminar borde inferior en el último elemento */
}

.search-item-image {
  width: 50px;
  height: auto;
  border-radius: 4px;
  margin-right: 15px; /* Aumentar margen para mayor separación */
  transition: transform 0.3s ease; /* Transición suave en el hover */
}

.search-item-title {
  text-decoration: none;
  color: black;
  flex-grow: 1; /* Asegurar que el título ocupe el espacio restante */
  transition: color 0.3s ease; /* Transición suave en el hover */
}

.search-item-title:hover {
  color: #9a51e2;
}

.search-item-image:hover {
  transform: scale(1.1); /* Ampliar la imagen en hover */
}

.no-results {
  color: red;
}

.error-message {
  color: red;
  margin-top: 10px;
}
