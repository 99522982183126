/* Main container layout */
.Dashboard {
  display: flex;
  padding: 10px 20px;
  background-color: #111;
  min-height: 60vh;
}

/* Sidebar styling */
.sidebar {
  width: 200px;
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 15px;
  color: white;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #9a51e2;
  transition: background-color 0.3s ease;
}

.sidebar ul li.active {
  background-color: #7a3fb7;
}

.sidebar ul li:hover {
  background-color: #6a32a7;
}

/* Main dashboard content styling */
.dashboard-content {
  flex-grow: 1;
  padding: 20px 40px;
  background-color: white;
  margin-left: 20px;
  border-radius: 8px;
}

.dashboard-content h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.dashboard-content .form-group {
  margin-bottom: 30px;
}

.dashboard-content .form-group h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.dashboard-content .form-group label {
  display: block;
  margin-bottom: 5px;
}

.dashboard-content .form-group input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dashboard-content button {
  background-color: #9a51e2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dashboard-content button:hover {
  background-color: #7a3fb7;
}

/* Logout button styling */
.logout-button {
  background-color: #9a51e2;
  color: white;
  padding: 14px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #7a3fb7;
}

/* Responsive for smaller screens */
@media (max-width: 768px) {
  .Dashboard {
    flex-direction: column;
    align-items: center; /* Center the sidebar and content */
    padding: 10px 20px; /* Adjust top padding for small screens */
  }

  .sidebar {
    width: 80%; /* Full width sidebar */
    max-width: 600px; /* Set a max width for consistency */
    margin-bottom: 20px;
    border-radius: 8px;
    padding-left: 40px; /* Align sidebar padding with content */
    padding-right: 40px; /* Same padding for right side */
  }

  .dashboard-content {
    width: 80%; /* Full width content */
    max-width: 600px; /* Set a max width for consistency */
    padding-left: 40px; /* Align content padding with sidebar */
    padding-right: 40px; /* Ensure right padding is consistent */
    margin-left: 0; /* No margin on small screens */
  }
}
/* Table styling for alternating row colors */
table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tr:hover {
  background-color: #f1f1f1;
}

/* Default style for inputs, selects, and buttons */
.form-group input, 
.form-group select, 
.form-group button {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 50%; /* Default to full width for smaller screens */
}

/* For larger screens, set a more reasonable width */
@media (min-width: 768px) {
  .form-group input, 
  .form-group select, 
  .form-group button {
    width: 10%; /* Reduce width for larger screens */
  }
}
.radio-group {
  display: flex;
  gap: 10px; /* Smaller space between Yes and No */
}

.radio-label {
  padding: 5px 15px; /* Smaller padding for the buttons */
  border: 2px solid #9a51e2; /* Purple border as main color */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-weight: bold;
  color: #9a51e2; /* Text color same as border */
  font-size: 14px; /* Slightly smaller font size */
}

/* Highlight when hovering */
.radio-label:hover {
  background-color: #7a3fb7; /* Purple background on hover */
  color: white; /* Change text color to white */
}

/* Style for selected option */
.radio-label.selected {
  background-color: #9a51e2; /* Main purple when selected */
  color: white;
  border-color: #9a51e2; /* Border color same as background */
}

/* Hide actual radio inputs */
input[type="radio"] {
  display: none;
}

/* Container for filter buttons */
.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

/* Button styling within filters */
.filters button {
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
 .filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%; /* Make the container full width */
  max-width: 200px; /* Adjust as needed */
}

.filters input,
.filters button {
  width: 100%; /* Set each item to full width of the container */
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
}
}


/* ManageContentUpload.css */
.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.progress-bar-fill {
  height: 20px;
  background-color: #4caf50;
  width: 0;
  border-radius: 5px;
  transition: width 0.2s ease-in-out;
}

/* Pagination container */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 5px;
}

/* Pagination buttons */
.pagination button {
  padding: 8px 12px;
  margin: 0 2px;
  border: 1px solid #9a51e2;
  background-color: white;
  color: #9a51e2;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination button:hover {
  background-color: #9a51e2;
  color: white;
}

.pagination button:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
  border-color: #e0e0e0;
}

/* Active page button */
.pagination button.active {
  background-color: #9a51e2;
  color: white;
  border-color: #9a51e2;
}

