/* Main navbar styling */
.navbar {
  position: relative; /* Added this line */
  z-index: 1000;      /* Ensure it sits above other elements */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: transparent;
  width: 100%;
  color: transparent;
  font-family: Arial, sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  height: 80px; /* Set a fixed height for the navbar */
  padding: 10px 20px; /* Reduced padding for smaller height */
}

/* Container for navbar content */
.navbar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

/* Left section for the logo */
.navbar-left {
  display: flex;
  align-items: center;
  margin-right: 50px; /* Increased margin to separate the logo from the rest */
}

.navbar-logo img {
  height: 65px;
}

/* Center section for links */
.navbar-links-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}

.navbar-links {
  list-style: none;
  display: flex;
  margin-left: 20px;
  padding: 0;
  align-items: center;
}

.navbar-links li {
  margin-left: 20px;
}

.navbar-links a {
  text-decoration: none;
  color: white; /* Text color white for contrast on transparent background */
  font-size: 18px;
  font-weight: 400;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #9a51e2; /* Hover color for links */
}

/* Dropdown styling */
.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background for dropdown */
  border-radius: 4px;
  z-index: 999;
}

.dropdown-menu li {
  padding: 10px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.dropdown-menu a {
  color: white;
  text-decoration: none;
  display: block;
  transition: color 0.3s ease;
  font-size: 14px;
  font-weight: 400; /* Ensure dropdown links are not bold */
}

.dropdown-menu a:hover {
  color: #9a51e2;
}

/* Search icon styling */
/* Right section for profile, login button, and hamburger icons */
.navbar-right {
  display: flex;
  align-items: center;
  gap: 15px; /* Adjust gap to control spacing between icons */
}
.search-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* User icon styling */
.navbar-user {
  display: flex;
  align-items: center;
}

/* Logout button styling */
.logout-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.logout-button:hover {
  color: #9a51e2; /* Hover color for logout */
}

/* Hamburger icon styling */
.menu-toggle {
  font-size: 35px;
  cursor: pointer;
  color: white; /* Keep the hamburger icon color white */
  margin-right: 25px;
  z-index: 1001;
}

/* For smaller screens (mobile) */
@media screen and (max-width: 768px) {
  .navbar-links-container {
    display: none;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    padding-top: 40px;
    padding-left: 20px;
  }

  .navbar-links-container.active {
    display: flex;
    transform: translateX(0);
  }

  .navbar-links {
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
  }

  .navbar-links li {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .menu-toggle {
    display: block;
    margin-left: 20px;
  }
}

/* For larger screens (desktop) */
@media screen and (min-width: 769px) {
  .navbar-right {
    margin-right: 30px; /* Added space between user icon and edge */
  }

  .menu-toggle {
    display: none; /* Hide hamburger on larger screens */
  }
}


