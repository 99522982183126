/* EmailVerified.css */

/* Container for the email verified component */
.email-verified-container {
  max-width: 500px;
  margin: 80px auto;
  padding: 40px 30px;
  background-color: #111; /* Matching the dark background */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  text-align: center;
}

/* Title styling */
.email-verified-container h2 {
  font-size: 24px; /* Consistent with .login-signup-title */
  color: #fff; /* White text */
  margin-bottom: 20px;
}

/* Message styling */
.email-verified-container p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #ddd; /* Light grey for readability */
}

/* Link/button styling */
.email-verified-link {
  display: inline-block;
  padding: 12px 20px;
  background-color: #9a51e2; /* Matching button color */
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.email-verified-link:hover {
  background-color: #7a3db0; /* Darker shade on hover */
}
