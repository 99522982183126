/* ResendVerification.css */

/* Container for the resend verification component */
.resend-verification-container {
  position: relative; /* Ensures the container flows within the document */
  max-width: 500px;
  margin: 80px auto; /* Centers the container horizontally with top margin */
  padding: 40px 30px;
  background-color: #111; /* Dark background matching the theme */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8); /* Subtle shadow for depth */
  z-index: 1; /* Ensures it sits above default elements but below overlays if any */
}

/* Title styling */
.resend-verification-container h2 {
  font-size: 24px; /* Consistent with .login-signup-title */
  color: #fff; /* White text */
  margin-bottom: 20px;
}

/* Form styling */
.resend-verification-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Form fields container */
.resend-verification-fields {
  width: 100%;
  margin-bottom: 20px;
}

/* Label styling */
.resend-verification-fields label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #ddd; /* Light grey for readability */
}

/* Input fields styling */
.resend-verification-input {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: #fff; /* White text */
  background-color: #333; /* Dark input background */
  border: 1px solid #555; /* Matching border color */
  border-radius: 5px;
  margin-bottom: 15px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.resend-verification-input:focus {
  border-color: #9a51e2; /* Purple border on focus */
  outline: none; /* Removes default outline */
}

/* Button styling */
.resend-verification-button {
  padding: 12px 20px;
  font-size: 16px;
  color: #fff; /* White text */
  background-color: #9a51e2; /* Purple background matching the theme */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.resend-verification-button:hover {
  background-color: #7a3db0; /* Darker purple on hover */
}

/* Message styling */
.message {
  margin-top: 20px;
  color: #fff; /* White text */
  font-size: 16px;
  text-align: center;
  /* No background or border to keep it plain */
}
