/* Full-screen overlay for the login/signup component (popup style) */
.login-signup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Inline form styling for direct access (no overlay) */
.login-signup-inline {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 20px;
}

/* Container for the login/signup form */
.login-signup-container {
  position: relative;
  width: 90%;
  max-width: 400px;
  background-color: #111;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  text-align: center;
}

/* Close button styling */
.product-info-card-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
}

/* Title styling */
.login-signup-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
}

/* Input fields styling */
.login-signup-input {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: #fff;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

/* Button styling */
.login-signup-button {
  padding: 12px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #9a51e2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-signup-button:hover {
  background-color: #7a3db0;
}

/* Toggle between Login and Signup */
.login-signup-toggle {
  margin-top: 15px;
  color: #fff;
}

.toggle-link {
  color: #9a51e2;
  cursor: pointer;
  text-decoration: underline;
}